<template>
  <div class="d-flex align-items-center">
    <span>https://xvr.art</span>
    <van-button type="primary" size="small" plain @click="handleCopy">{{ $t('copy_link') }}</van-button>
  </div>
</template>

<script>
import { Toast } from 'vant';
import useClipboard from 'vue-clipboard3'
import { useI18n } from "vue-i18n";
export default {
  setup() {
    const { toClipboard } = useClipboard()
    const { t } = useI18n({ useScope: "global" });

    const handleCopy = async () => {
      try {
        await toClipboard('https://xvr.art')
        Toast.success(t('copy_successful'))
      } catch (e) {
        Toast.success(t('copy_successful'))
      }
    };

    return { handleCopy }
  }
}
</script>

<style lang="less" scoped>
.van-button {
  border-radius: 20px;
  margin-left: 10px;
}
</style>
