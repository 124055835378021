import ApiService from "./api.service";

export function getTicketDetail(scenic_spot_ticket_id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpotTicket/detail", {
      scenic_spot_ticket_id,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function purchase(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpotTicket/purchase", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function listForSale(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpotTicket/listForSale", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function orderList(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpotTicket/orderList", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function checkPaymentStatus(trade_no) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpotTicket/checkPaymentStatus", { trade_no })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getPaypalOrder(order_id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpotTicket/getPaypalOrder", { order_id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
